import React, { useEffect } from "react";
import router from "next/router";

const Home = () => {

  useEffect(() => {
    router.push("/home");
  });

  return <div></div>;
};

export default Home;
